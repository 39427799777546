import React from "react"
import styles from './index.module.css'
import SEO from "../components/seo"
import MainLayout from "../layouts/MainLayout"

const TermsPage = () => {
  return (
    <MainLayout>
      <SEO title="Crowdlever terms and conditions" />
      <div className={styles.section}>
      <h1>Terms &amp; Conditions</h1>
<p>By using Crowdlever, you are agreeing to be bound by the following 
terms and conditions (&#x201c;Terms of Service&#x201d;).</p>

<p>Crowdlever reserves the right to update and change the Terms of Service from time to time without 
notice. Any new features that augment or enhance the current Service, including the release of new tools and
 resources, shall be subject to the Terms of Service. Continued use of the Service after any such changes 
 shall constitute your consent to such changes. You can review the most current version of the Terms &amp; Conditions 
 at any time at: <a href="/terms">Terms & Conditions</a></p>

<p>Violation of any of the terms below will result in the termination of your Account. While Crowdlever 
prohibits such conduct and Content on the Service, you understand and agree that Crowdlever cannot be 
responsible for the Content posted on the Service and you nonetheless may be exposed to such materials. You agree to use 
the Service at your own risk.</p>

<h2>Account Terms</h2>

<ol>
  <li>You must be 13 years or older to use this Service.</li>
  <li>You must be a human. Accounts registered by &#8220;bots&#8221; or other automated methods are not permitted.</li>
  <li>You must provide your legal full name, a valid email address, and any other information requested in order to 
  complete the signup process.</li>

  <li>Your login may only be used by one person &#8211; a single login shared by multiple people is not permitted. 
  You may create separate logins for as many people as you'd like.</li>
  <li>You are responsible for maintaining the security of your account and password. Crowdlever cannot 
  and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
  <li>You are responsible for all Content posted and activity that occurs under your account (even when Content is posted by 
  others who have accounts under your account).</li>
  <li>If you are using a free account you are not permitted to block ads.</li>
  <li>One person or legal entity may not maintain more than one free account.</li>

  <li>You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, 
  violate any laws in your jurisdiction (including but not limited to copyright laws).</li>
</ol>

<h2>Cancellation and Termination</h2>

<ol>
  <li>All of your Content will be immediately deleted from the Service upon cancellation. This information can not be recovered once your account is cancelled.</li>
  <li>If you cancel the Service before the end of your subscription expiry date, your cancellation will take effect at the end of your subscription period and you will not be charged again.</li>
  <li>Crowdlever, in its sole discretion, has the right to suspend or terminate your account 
  and refuse any and all current or future use of the Service, or any other service, 
  for any reason at any time. Such termination of the Service will result in the deactivation or deletion of 
  your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. 
  Crowdlever reserves the right to refuse service to anyone for any reason at any time.</li>
</ol>

<h2>Modifications to the Service and Prices</h2>

<ol>
  <li>Crowdlever reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.</li>
  <li>Prices of all Services, including but not limited to monthly and yearly subscription plan fees to the Service, 
  are subject to change upon 30 days notice from us. Such notice may be provided at any time by posting 
  the changes to the <a href="/">Crowdlever</a> site.</li>
  <li>Crowdlever shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.</li>
</ol>

<h2>Copyright and Content Ownership</h2>

<ol>
  <li>All content posted on the Service is must comply with copyright law.</li>
  <li>We claim no intellectual property rights over the material you provide to the Service. Your profile and materials uploaded remain yours.</li>
  <li>Crowdlever does not pre-screen Content, but Crowdlever and its designee have the right (but not the obligation) in their sole discretion to refuse or remove any Content that is available via the Service.</li>
</ol>

<h2>General Conditions</h2>

<ol>
  <li>Your use of the Service is at your sole risk. The service is provided on an &#8220;as is&#8221; and &#8220;as available&#8221; basis.</li>
  <li>Technical support is only provided to paying account holders.</li>

  <li>You understand that Crowdlever uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.</li>
  <li>You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service, Crowdlever, or any other Crowdlever service.</li>
  <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by Crowdlever.</li>
  <li>We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&#8217;s intellectual property or these Terms of Service.</li>
  <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of any Crowdlever customer, employee, member, or officer will result in immediate account termination.</li>
  <li>You understand that the technical processing and transmission of the Service, including your Content, may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</li>

  <li>You must not upload, post, host, or transmit unsolicited email, SMSs, or &#8220;spam&#8221; messages.</li>
  <li>You must not transmit any worms or viruses or any code of a destructive nature.</li>
  <li>Crowdlever does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the service will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations, and (v) any errors in the Service will be corrected.</li>
  <li>You expressly understand and agree that Crowdlever shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Crowdlever has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.</li>

  <li>The failure of Crowdlever to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. The Terms of Service constitutes the entire agreement between you and Crowdlever and govern your use of the Service, superceding any prior agreements between you and Crowdlever (including, but not limited to, any prior versions of the Terms of Service).</li>
  <li>Questions about the Terms of Service should be sent to support at support@crowdlever.io.</li>
</ol>
      </div>
    </MainLayout>
  )
}

export default TermsPage